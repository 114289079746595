import styled, { css } from 'styled-components';
import tokens from '@te-digi/styleguide-tokens';
import { Button } from '../Button/Button.js';

// TODO: Refactor nested classes to use styled-components
const StyledTagWrapper = styled.div.withConfig({
  componentId: "sg1690__sc-1goy7hi-0"
})(["position:relative;"]);
const StyledTag = styled.div.withConfig({
  componentId: "sg1690__sc-1goy7hi-1"
})(["background-color:transparent;border-radius:", ";border:0;cursor:pointer;display:block;padding:0;position:relative;text-align:left;transition:box-shadow ", ";width:100%;"], tokens.radius.md, tokens.transition.fast);
const StyledInput = styled.input.withConfig({
  componentId: "sg1690__sc-1goy7hi-2"
})(["height:100%;opacity:0;position:absolute;width:100%;z-index:-1;&:focus ~ ", "{box-shadow:", ";outline:none;}"], StyledTag, tokens.shadow.focus);
const StyledTagWithActions = styled.div.withConfig({
  componentId: "sg1690__sc-1goy7hi-3"
})(["display:flex;position:relative;"]);
const StyledTagItem = styled.div.withConfig({
  componentId: "sg1690__sc-1goy7hi-4"
})(["display:flex;height:", ";&:first-child{border-bottom-left-radius:", ";border-top-left-radius:", ";}&:last-child{border-bottom-right-radius:", ";border-top-right-radius:", ";}", " &,", " &{background-color:", ";border-color:", ";border-style:solid;border-width:0;border-bottom-width:1px;border-top-width:1px;color:", ";&:first-child{border-left-width:1px;}&:last-child{border-right-width:1px;}}", " &{background-color:", ";color:", ";}", ".tag-primary &{border-color:", ";color:", ";}", ".tag-primary &{background-color:", ";border-color:", ";color:", ";}label", ":active.active &{background-color:", ";border-color:", ";}label", ".tag-primary:active.active &{background-color:", ";border-color:", ";}label", ":active:not(.active) &{background-color:", ";color:", ";}label", ".tag-primary:active:not(.active) &{background-color:", ";}label", ":hover.active &{border-color:", ";}label", ".tag-primary:hover.active &{border-color:", ";}label", ":hover:not(.active) &{border-color:", ";color:", ";}label", ".tag-primary:hover:not(.active) &{border-color:", ";}"], tokens.space.xxxl, tokens.radius.md, tokens.radius.md, tokens.radius.md, tokens.radius.md, StyledTag, StyledTagWithActions, tokens.color.white, tokens.color.secondary, tokens.color.secondary, StyledTagWithActions, tokens.color['secondary-lightest'], tokens.color['secondary-dark'], StyledTag, tokens.color.primary, tokens.color.primary, StyledTagWithActions, tokens.color['primary-lightest'], tokens.color.primary, tokens.color['primary-dark'], StyledTag, tokens.color['secondary-dark'], tokens.color['secondary-dark'], StyledTag, tokens.color['primary-dark'], tokens.color['primary-dark'], StyledTag, tokens.color['secondary-dark'], tokens.color.white, StyledTag, tokens.color['primary-dark'], StyledTag, tokens.color['secondary-light'], StyledTag, tokens.color['primary-light'], StyledTag, tokens.color['secondary-light'], tokens.color.white, StyledTag, tokens.color['primary-light']);
const StyledTagButton = styled(Button).withConfig({
  componentId: "sg1690__sc-1goy7hi-5"
})(["&&{border:0;border-radius:0;flex:0 0 auto;height:auto;min-width:0;padding:0;width:calc(3rem - 2px);&:not(:hover):not(:focus){background-color:transparent;}}"]);
const StyledTagLabel = styled.div.withConfig({
  componentId: "sg1690__sc-1goy7hi-6"
})(["align-items:center;display:flex;flex:0 0 auto;font-weight:", ";line-height:", ";padding:0 ", ";transition:background-color ", ";& + &{padding-left:0;}", ".active &,", ".active &{background-color:", ";color:", ";}", ".tag-primary.active &,", ".tag-primary.active &{background-color:", ";}label", ":hover &{background-color:", ";}label", ".tag-primary:hover &{background-color:", ";}label", ":active.active &{background-color:", ";}label", ".tag-primary:active.active &{background-color:", ";}&.tag-label-main{flex:1 1 auto;max-width:20rem;overflow:hidden;}&.tag-label-main--block{flex:1 1 auto;overflow:hidden;}&.tag-label-icon{flex:0 0 auto;}"], tokens['font-weight'].normal, tokens['line-height'].xs, tokens.space.sm, tokens.transition.fast, StyledTag, StyledTagWithActions, tokens.color.secondary, tokens.color.white, StyledTag, StyledTagWithActions, tokens.color.primary, StyledTag, tokens.color['secondary-light'], StyledTag, tokens.color['primary-light'], StyledTag, tokens.color['secondary-dark'], StyledTag, tokens.color['primary-dark']);
const StyledTagItems = styled.div.withConfig({
  componentId: "sg1690__sc-1goy7hi-7"
})(["display:flex;width:100%;", " ", ".tag-block & .tag-item-label,", ".tag-block & .tag-item-label{flex:1 1 auto;}", " .tag-icon{display:block;", ":hover.active &{display:none;}}.tag-icon-hover{display:none;", ":hover.active &{display:block;}}", ""], props => props.size === 'xs' && css(["", "{font-size:", ";height:", ";}"], StyledTagItem, tokens['font-size'].sm, tokens.space.xl), StyledTag, StyledTagWithActions, props => props.size === 'xs' && css(["", "{padding:0 ", ";}"], StyledTagLabel, tokens.space.xs), StyledTag, StyledTag, props => props.size === 'xs' && css(["", "{width:calc(2rem - 2px);}"], StyledTagButton));
const StyledTagDivider = styled.div.withConfig({
  componentId: "sg1690__sc-1goy7hi-8"
})(["background-color:", ";flex:0 0 auto;position:relative;width:1px;", ".tag-primary &,", ".tag-primary &{background-color:", ";}"], tokens.color.secondary, StyledTag, StyledTagWithActions, tokens.color.primary);

export { StyledInput, StyledTag, StyledTagButton, StyledTagDivider, StyledTagItem, StyledTagItems, StyledTagLabel, StyledTagWithActions, StyledTagWrapper };
