import styled from 'styled-components';
import tokens from '@te-digi/styleguide-tokens';

/**
 * Duplicated in _breadcrumb.scss.
 */
const StyledBreadcrumb = styled.ol.withConfig({
  componentId: "sg1690__sc-1csono8-0"
})(["display:flex;flex-wrap:wrap;font-size:", ";list-style:none;margin-bottom:", ";padding:0;"], tokens['font-size'].sm, tokens.space.lg);
const StyledBreadcrumbItem = styled.li.withConfig({
  componentId: "sg1690__sc-1csono8-1"
})(["display:flex;& + &{padding-left:", ";&::before{color:", ";content:'\u203A';display:inline-block;padding-right:", ";}}"], tokens.space.xs, tokens.color['neutral-5'], tokens.space.xs);

export { StyledBreadcrumb, StyledBreadcrumbItem };
